






















































import { Component, Mixins } from 'vue-property-decorator'
import StateMixin from '@/mixins/state'

@Component({})
export default class AppAnnouncementDismissMenu extends Mixins(StateMixin) {
  get presets () {
    return [
      { hours: 1 },
      { days: 1 },
      { days: 7 }
    ].map(v => {
      const duration = this.$dayjs.duration(v)

      return ({
        label: duration.humanize(),
        delay: duration.asSeconds()
      })
    })
  }
}
