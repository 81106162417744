





































































import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({})
export default class ApiKeyDialog extends Vue {
  @Prop({ type: Boolean, required: true })
  value!: boolean

  get apiKey () {
    return this.$store.getters['auth/getApiKey']
  }

  handleRefreshApiKey () {
    this.$store.dispatch('auth/refreshApiKey')
  }
}
