








































import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({
  components: {}
})
export default class AppCodeView extends Vue {
  @Prop({ type: String, required: true })
  value!: string

  copied = false

  handleCopyClick () {
    if (
      navigator.clipboard &&
      this.value
    ) {
      navigator.clipboard.writeText(this.value)
      this.copied = true
      setTimeout(() => { this.copied = false }, 1200)
    }
  }
}
