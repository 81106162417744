




























































































































































































import { Component, Mixins } from 'vue-property-decorator'
import JobHistoryItemStatus from './JobHistoryItemStatus.vue'
import FilesMixin from '@/mixins/files'
import getFilePaths from '@/util/get-file-paths'
import { HistoryItem } from '@/store/history/types'
import { SocketActions } from '@/api/socketActions'
import { AppTableHeader } from '@/types'

@Component({
  components: {
    JobHistoryItemStatus
  }
})
export default class JobHistory extends Mixins(FilesMixin) {
  expanded: HistoryItem[] = []
  search = ''

  get headers (): AppTableHeader[] {
    const headers = [
      { text: '', value: 'data-table-icons', sortable: false, width: '24px' },
      { text: this.$tc('app.general.table.header.name'), value: 'filename' },
      { text: this.$tc('app.general.table.header.status'), value: 'status', configurable: true },
      { text: this.$tc('app.general.table.header.start_time'), value: 'start_time', configurable: true },
      { text: this.$tc('app.general.table.header.end_time'), value: 'end_time', configurable: true },
      { text: this.$tc('app.general.table.header.print_duration'), value: 'print_duration', configurable: true },
      { text: this.$tc('app.general.table.header.total_duration'), value: 'total_duration', configurable: true },
      { text: this.$tc('app.general.table.header.filament_used'), value: 'filament_used', configurable: true },
      { text: this.$t('app.general.table.header.size'), value: 'metadata.size', width: '1%', configurable: true },
      { text: this.$tc('app.general.table.header.actions'), value: 'actions', sortable: false, align: 'end' }
    ]
    const key = 'history'
    return this.$store.getters['config/getMergedTableHeaders'](headers, key)
  }

  get visibleHeaders (): AppTableHeader[] {
    return this.headers.filter(header => header.visible || header.visible === undefined)
  }

  get history () {
    return this.$store.getters['history/getHistory']
  }

  getRowClasses (item: HistoryItem) {
    if (!item.exists) return 'v-data-table__inactive'
    return ''
  }

  getFilePaths (filename: string) {
    return getFilePaths(filename, 'gcodes')
  }

  getFilename (filename: string) {
    return filename.split('/').pop() || ''
  }

  handleRemoveJob (job: HistoryItem) {
    SocketActions.serverHistoryDeleteJob(job.job_id)
  }

  isExpanded (row: HistoryItem) {
    if (this.expanded.length <= 0) return false
    const r = this.expanded[0] as HistoryItem
    return (row.job_id === r.job_id)
  }

  toggleRowExpand (row: HistoryItem) {
    if (this.isExpanded(row)) {
      this.expanded = []
    } else {
      this.expanded = [row]
    }
  }
}
