var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"file-system"},[_c('v-toolbar',{attrs:{"dense":""}},[_c('v-spacer'),_c('div',{staticClass:"mr-1",staticStyle:{"max-width":"160px"}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","single-line":"","hide-details":"","append-icon":"$magnify"},on:{"keyup":function($event){return _vm.$emit('update:search', _vm.search);}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),(_vm.headers)?_c('app-column-picker',{attrs:{"key-name":"history","headers":_vm.headers}}):_vm._e()],1),_c('v-data-table',{attrs:{"items":_vm.history,"headers":_vm.visibleHeaders,"items-per-page":15,"item-class":_vm.getRowClasses,"single-expand":true,"search":_vm.search,"expanded":_vm.expanded,"mobile-breakpoint":"0","item-key":"job_id","sort-by":"start_time","sort-desc":""},scopedSlots:_vm._u([{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"pa-4",attrs:{"colspan":headers.length}},[_c('div',{staticClass:"chip-group"},[_vm._l((Object.keys(item.metadata)),function(key,i){return [(key !== 'thumbnails')?_c('v-chip',{key:i,attrs:{"small":""}},[_vm._v(" "+_vm._s(key)+": "+_vm._s(item.metadata[key])+" ")]):_vm._e()]})],2)])]}},{key:"item.data-table-icons",fn:function(ref){
var item = ref.item;
return [(!item.exists)?_c('v-icon',{staticClass:"mr-2",attrs:{"color":"secondary"}},[_vm._v(" $fileCancel ")]):_vm._e(),(item.exists && !item.metadata.thumbnails)?_c('v-icon',{staticClass:"mr-2"},[_vm._v(" $fileDocument ")]):_vm._e(),(item.exists && item.metadata.thumbnails && item.metadata.thumbnails.length)?_c('img',{staticClass:"mr-2 file-icon-thumb",attrs:{"src":_vm.getThumbUrl(item.metadata.thumbnails, _vm.getFilePaths(item.filename).path, false, item.metadata.modified),"width":24}}):_vm._e()]}},{key:"item.filename",fn:function(ref){
var item = ref.item;
return [_c('span',{},[_vm._v(" "+_vm._s(_vm.getFilePaths(item.filename).filename)+" ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('job-history-item-status',{attrs:{"job":item}})]}},{key:"item.start_time",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(_vm.$filters.formatDateTime(item.start_time, _vm.$store.state.config.uiSettings.general.dateformat + ' YYYY - ' + _vm.$store.state.config.uiSettings.general.timeformat))+" ")])]}},{key:"item.end_time",fn:function(ref){
var item = ref.item;
return [(item.status !== 'in_progress')?_c('span',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(_vm.$filters.formatDateTime(item.end_time, _vm.$store.state.config.uiSettings.general.dateformat + ' YYYY - ' + _vm.$store.state.config.uiSettings.general.timeformat))+" ")]):_c('span',[_vm._v("--")])]}},{key:"item.print_duration",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(_vm.$filters.formatCounterTime(item.print_duration))+" ")])]}},{key:"item.total_duration",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(_vm.$filters.formatCounterTime(item.total_duration))+" ")])]}},{key:"item.filament_used",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(_vm.$filters.getReadableLengthString(item.filament_used))+" ")])]}},{key:"item.metadata.size",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(_vm.$filters.getReadableFileSizeString(item.metadata.size))+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-no-wrap"},[_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.handleRemoveJob(item)}}},[_c('v-icon',{attrs:{"small":"","color":""}},[_vm._v(" $delete ")])],1),_c('v-btn',{staticClass:"v-data-table__expand-icon",class:{ 'v-data-table__expand-icon--active': _vm.isExpanded(item) },attrs:{"color":"","icon":"","small":""},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.toggleRowExpand(item)}}},[_c('v-icon',[_vm._v("$chevronDown")])],1)],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }