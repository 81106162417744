






































































import { Component, Mixins } from 'vue-property-decorator'
import StateMixin from '@/mixins/state'
import ToolheadMixin from '@/mixins/toolhead'
import { Waits } from '@/globals'

@Component({})
export default class ExtruderMoves extends Mixins(StateMixin, ToolheadMixin) {
  waits = Waits
  feedSpeed = -1
  feedLength = -1
  valid = true

  rules = {
    min: (v: number) => {
      return (v >= 0.1) || this.$t('app.general.simple_form.error.min', { min: 0.1 })
    },
    maxSpeed: (v: number) => {
      return (v <= this.maxExtrudeSpeed) || this.$t('app.general.simple_form.error.max', { max: this.maxExtrudeSpeed })
    },
    maxLength: (v: number) => {
      return (v <= this.maxExtrudeLength) || this.$t('app.general.simple_form.error.max', { max: this.maxExtrudeLength })
    }
  }

  get maxExtrudeSpeed () {
    return this.$store.getters['printer/getPrinterSettings']('extruder.max_extrude_only_velocity')
  }

  get maxExtrudeLength () {
    return this.$store.getters['printer/getPrinterSettings']('extruder.max_extrude_only_distance')
  }

  get extrudeSpeed () {
    return (this.feedSpeed === -1)
      ? this.$store.state.config.uiSettings.general.defaultExtrudeSpeed
      : this.feedSpeed
  }

  set extrudeSpeed (val: number) {
    this.feedSpeed = val
  }

  get extrudeLength () {
    return (this.feedLength === -1)
      ? this.$store.state.config.uiSettings.general.defaultExtrudeLength
      : this.feedLength
  }

  set extrudeLength (val: number) {
    this.feedLength = val
  }

  sendRetractGcode (amount: number, rate: number, wait?: string) {
    if (this.valid) {
      const gcode = `M83
        G1 E-${amount} F${rate * 60}`
      this.sendGcode(gcode, wait)
    }
  }

  sendExtrudeGcode (amount: number, rate: number, wait?: string) {
    if (this.valid) {
      const gcode = `M83
        G1 E${amount} F${rate * 60}`
      this.sendGcode(gcode, wait)
    }
  }
}
