





















































import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({})
export default class MacroCategoryDialog extends Vue {
  @Prop({ type: Boolean, required: true })
  value!: boolean

  @Prop({ type: String, required: true })
  title!: string

  @Prop({ type: String, required: true })
  label!: string

  @Prop({ type: Array, required: false })
  rules!: []

  @Prop({ type: String, required: true })
  name!: string

  newName = ''
  valid = true

  mounted () {
    this.newName = this.name
  }

  get categories () {
    return this.$store.getters['macros/getCategories']
  }

  handleSave () {
    if (this.valid) {
      this.$emit('save', this.newName)
      this.$emit('input', false)
    }
  }
}
