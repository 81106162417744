







































































































import { Component, Vue } from 'vue-property-decorator'
import MacroSettingsDialog from './MacroSettingsDialog.vue'
import { Macro, MacroCategory } from '@/store/macros/types'
import store from '@/store'

const routeGuard = (to: any) => {
  // No need to translate here, these are just used for the route.
  const id = to.params.categoryId
  const categories = store.getters['macros/getCategories']
  const i = categories.findIndex((c: MacroCategory) => c.id === id)
  return (i > -1 || id === '0')
    ? true
    : { path: '/settings', hash: 'macros' }
}

@Component({
  components: {
    MacroSettingsDialog
  }
})
export default class MacroSettings extends Vue {
  search = ''
  categoryId: string | undefined = undefined

  dialogState: any = {
    open: false,
    macro: null
  }

  get macros () {
    const id = this.categoryId
    const macros = this.$store.getters['macros/getMacrosByCategory'](id)
      .filter((macro: Macro) => (!this.search || this.search === '') ? true : macro.name.toLowerCase().includes(this.search.toLowerCase()))
    return macros
  }

  get categories (): MacroCategory[] {
    return this.$store.getters['macros/getCategories']
  }

  get category (): MacroCategory | undefined {
    if (this.categoryId === '0') return { id: '0', name: this.$tc('app.general.label.uncategorized') }
    return this.categories.find(category => category.id === this.categoryId)
  }

  beforeRouteEnter (to: any, from: any, next: any) {
    next(routeGuard(to))
  }

  beforeRouteUpdate (to: any, from: any, next: any) {
    next(routeGuard(to))
  }

  created () {
    this.search = ''
    this.categoryId = this.$route.params.categoryId
  }

  handleBack () {
    this.$router.go(-1)
  }

  handleSettingsDialog (macro: Macro) {
    this.dialogState.macro = macro
    this.dialogState.open = true
  }

  handleAllOn () {
    this.$store.dispatch('macros/saveAllOn', this.macros)
  }

  handleAllOff () {
    this.$store.dispatch('macros/saveAllOff', this.macros)
  }

  handleMacroVisible (macro: Macro, value: boolean) {
    const newMacro = {
      ...macro, visible: value
    }
    this.$store.dispatch('macros/saveMacro', newMacro)
  }
}
