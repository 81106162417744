


























































































































































import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({})
export default class VersionInformationDialog extends Vue {
  @Prop({ type: Boolean, required: true })
  value!: boolean
}
