





























import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({})
export default class AppInlineHelp extends Vue {
  @Prop({ type: String, required: false })
  tooltip!: string

  @Prop({ type: String, default: 'primary' })
  type!: string

  @Prop({ type: Boolean, default: false })
  top!: boolean

  @Prop({ type: Boolean, default: false })
  bottom!: boolean

  @Prop({ type: Boolean, default: false })
  left!: boolean

  @Prop({ type: Boolean, default: false })
  right!: boolean

  @Prop({ type: Boolean, default: false })
  small!: boolean
}
