





























import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({})
export default class AppBtnToolheadMove extends Vue {
  @Prop({ type: String, required: true })
  icon!: string

  @Prop()
  disabled!: boolean

  @Prop({ type: String, default: 'btncolor' })
  color!: string

  @Prop({ type: Boolean, default: false })
  loading!: boolean

  @Prop({ type: String, default: '' })
  badge!: string

  @Prop({ type: String, default: '' })
  tooltip!: string

  @Prop({ type: Boolean, default: false })
  smallIcon!: boolean

  get hasDefaultSlot () {
    return !!this.$slots.default || !!this.$scopedSlots.default
  }
}
