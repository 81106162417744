import { render, staticRenderFns } from "./AppBtnCollapse.vue?vue&type=template&id=6cb08b3e&scoped=true&"
import script from "./AppBtnCollapse.vue?vue&type=script&lang=ts&"
export * from "./AppBtnCollapse.vue?vue&type=script&lang=ts&"
import style0 from "./AppBtnCollapse.vue?vue&type=style&index=0&id=6cb08b3e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6cb08b3e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCheckbox,VIcon,VLayout})
