


















import { Component, Mixins } from 'vue-property-decorator'
import StateMixin from '@/mixins/state'

@Component({})
export default class SystemLayout extends Mixins(StateMixin) {
  get layoutMode () {
    return this.$store.state.config.layoutMode
  }

  set layoutMode (val: boolean) {
    this.$store.commit('config/setLayoutMode', val)
    this.close()
  }

  close () {
    this.$emit('click')
  }
}
