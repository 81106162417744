import { render, staticRenderFns } from "./PrinterStatsCard.vue?vue&type=template&id=3c033fe6&scoped=true&"
import script from "./PrinterStatsCard.vue?vue&type=script&lang=ts&"
export * from "./PrinterStatsCard.vue?vue&type=script&lang=ts&"
import style0 from "./PrinterStatsCard.vue?vue&type=style&index=0&id=3c033fe6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3c033fe6",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCard,VCardText,VCol,VIcon,VRow})
