



















































































































import { Component, Mixins } from 'vue-property-decorator'
import VersionStatus from './VersionStatus.vue'
import VersionCommitHistoryDialog from './VersionInformationDialog.vue'
import StateMixin from '@/mixins/state'
import { SocketActions } from '@/api/socketActions'
import { ArtifactVersion, HashVersion, OSPackage } from '@/store/version/types'

@Component({
  components: {
    VersionStatus,
    VersionCommitHistoryDialog
  }
})
export default class VersionSettings extends Mixins(StateMixin) {
  informationDialogState: any = {
    open: false,
    component: null
  }

  get components () {
    return this.$store.getters['version/getVisibleComponents']
  }

  get isRefreshing () {
    return this.$store.state.version.refreshing
  }

  get hasUpdates () {
    const d = this.$store.getters['version/hasUpdates']
    return d
  }

  get enableNotifications () {
    return this.$store.state.config.uiSettings.general.enableVersionNotifications
  }

  set enableNotifications (value: boolean) {
    this.$store.dispatch('config/saveByPath', {
      path: 'uiSettings.general.enableVersionNotifications',
      value,
      server: true
    })
  }

  packageTitle (component: HashVersion | OSPackage | ArtifactVersion) {
    if (component.key === 'system') {
      return this.$t('app.version.label.os_packages')
    }

    return component.key
  }

  hasUpdate (component: string) {
    return this.$store.getters['version/hasUpdate'](component)
  }

  inError (component: HashVersion | OSPackage | ArtifactVersion) {
    const dirty = ('is_dirty' in component) ? component.is_dirty : false
    const valid = ('is_valid' in component) ? component.is_valid : true
    return (dirty || !valid)
  }

  packageUrl (component: HashVersion | OSPackage | ArtifactVersion) {
    if (component.key === 'klipper') return 'https://github.com/KevinOConnor/klipper/commits/master'
    if (component.key === 'moonraker') return 'https://github.com/Arksine/moonraker/commits/master'
    if (component.key === 'fluidd' && 'name' in component && component.name === 'fluidd') return 'https://github.com/fluidd-core/fluidd/releases'
  }

  // Will attempt to update the requirec component based on its type.
  handleUpdateComponent (key: string) {
    this.$store.dispatch('version/onUpdateStatus', { busy: true })
    switch (key) {
      case 'klipper':
        SocketActions.machineUpdateKlipper()
        break
      case 'moonraker':
        SocketActions.machineUpdateMoonraker()
        break
      case 'system':
        SocketActions.machineUpdateSystem()
        break
      default: // assume a client update
        SocketActions.machineUpdateClient(key)
        break
    }
    // Close the drawer
    this.$emit('click')
  }

  // Will attempt to recover a component based on its type and current status.
  handleRecoverComponent (component: HashVersion | OSPackage | ArtifactVersion) {
    this.$store.dispatch('version/onUpdateStatus', { busy: true })
    const dirty = ('is_dirty' in component) ? component.is_dirty : false
    const valid = ('is_valid' in component) ? component.is_valid : true
    if (dirty) {
      SocketActions.machineUpdateRecover(component.key, false)
    }
    if (!valid) {
      SocketActions.machineUpdateRecover(component.key, true)
    }
  }

  forceCheck () {
    SocketActions.machineUpdateStatus(true)
  }

  getBaseUrl (component: HashVersion | ArtifactVersion) {
    if ('owner' in component) {
      return `https://github.com/${component.owner}/${component.key}`
    }
    return ''
  }

  handleInformationDialog (component: HashVersion | OSPackage | ArtifactVersion) {
    if (
      'commits_behind' in component ||
      'package_list' in component
    ) {
      this.informationDialogState = {
        open: true,
        component
      }
    } else {
      window.open(`${this.getBaseUrl(component)}/releases`)
    }
  }
}
