
























































































































import { Component, Mixins, Prop } from 'vue-property-decorator'
import FilesMixin from '@/mixins/files'
import StateMixin from '@/mixins/state'
import { AppDirectory, AppFile, AppFileWithMeta } from '@/store/files/types'

/**
 * NOTE: Generally, moonraker expects the paths to include the root.
 */
@Component({})
export default class FileSystemContextMenu extends Mixins(StateMixin, FilesMixin) {
  @Prop({ type: String, required: true })
  root!: string

  @Prop({ type: Boolean, default: false })
  open!: boolean

  @Prop({ type: Object, required: true })
  file!: AppDirectory | AppFile | AppFileWithMeta

  @Prop({ type: Number, required: true })
  positionX!: number

  @Prop({ type: Number, required: true })
  positionY!: number

  get rootProperties () {
    return this.$store.getters['files/getRootProperties'](this.root)
  }

  get canPreheat () {
    return (
      'first_layer_extr_temp' in this.file &&
      'first_layer_bed_temp' in this.file &&
      !this.printerPrinting &&
      !this.printerPaused &&
      this.klippyReady
    )
  }

  get canPreviewGcode () {
    return (this.$store.getters['layout/isEnabledInLayout']('dashboard', 'gcode-preview-card') && this.root === 'gcodes')
  }
}
