

































import { Component, Mixins } from 'vue-property-decorator'
import StateMixin from '@/mixins/state'

@Component({})
export default class KlippyStatusCard extends Mixins(StateMixin) {
}
