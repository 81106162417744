var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.klippyConnected)?_c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('app-btn',_vm._g(_vm._b({staticClass:"me-2 mb-2",attrs:{"block":"","color":"primary"},on:{"click":_vm.restartKlippy}},'app-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t('app.general.btn.restart_service_klipper'))+" ")])]}}],null,false,3735503835)},[_c('span',[_vm._v(_vm._s(_vm.$t('app.general.tooltip.reload_klipper')))])])],1):_c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('app-btn',_vm._g(_vm._b({staticClass:"me-2 mb-2",attrs:{"block":"","color":"primary"},on:{"click":_vm.serviceRestartKlipper}},'app-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t('app.general.btn.restart_service_klipper'))+" ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('app.general.tooltip.restart_klipper')))])])],1),(_vm.klippyConnected)?_c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('app-btn',_vm._g(_vm._b({staticClass:"me-2 mb-2",attrs:{"block":"","color":"primary"},on:{"click":_vm.firmwareRestartKlippy}},'app-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t('app.general.btn.restart_firmware'))+" ")])]}}],null,false,3161813664)},[_c('span',[_vm._v(_vm._s(_vm.$t('app.general.tooltip.reload_restart_klipper')))])])],1):_vm._e(),_c('app-btn',{staticClass:"me-2 mb-2",attrs:{"block":""},on:{"click":function($event){return _vm.getKlippyLog()}}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v(" $download ")]),_vm._v(" Klippy.log ")],1),_c('app-btn',{staticClass:"me-2 mb-2",attrs:{"block":""},on:{"click":function($event){return _vm.getMoonrakerLog()}}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v(" $download ")]),_vm._v(" Moonraker.log ")],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }