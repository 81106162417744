












































































































































import { Macro } from '@/store/macros/types'
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'

@Component({})
export default class MacroMoveDialog extends Vue {
  @Prop({ type: Boolean, required: true })
  value!: boolean

  @Prop({ type: Object, required: true })
  macro!: Macro

  assign = null
  valid = false
  newMacro: Macro | null = null

  created () {
    this.newMacro = { ...this.macro }
  }

  @Watch('value')
  onOpen () {
    this.newMacro = { ...this.macro }
  }

  get categories () {
    // Grabs all categories and filters by the currently defined one.
    const categories = [...this.$store.getters['macros/getCategories']]
    categories.unshift({ name: this.$t('app.general.label.uncategorized'), id: '0' })
    return categories
  }

  get color () {
    const theme = this.$store.getters['config/getTheme']
    if (this.newMacro && this.newMacro.color !== '') {
      return this.newMacro.color
    }
    return theme.currentTheme.secondary
  }

  handleColorChange (color: any) {
    if (this.newMacro) this.newMacro.color = color.color.hexString
  }

  handleResetColor () {
    if (this.newMacro) this.newMacro.color = ''
  }

  handleSave () {
    this.$store.dispatch('macros/saveMacro', this.newMacro)
    this.$emit('input', false)
  }
}
