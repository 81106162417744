





























































import { Component, Mixins, Prop } from 'vue-property-decorator'
import StateMixin from '@/mixins/state'
import GcodePreviewControlCheckbox from '@/components/widgets/gcode-preview/GcodePreviewControlCheckbox.vue'
import FilesMixin from '@/mixins/files'
import { AppFile } from '@/store/files/types'
import { AxiosResponse } from 'axios'

@Component({
  components: { GcodePreviewControlCheckbox }
})
export default class GcodePreviewControls extends Mixins(StateMixin, FilesMixin) {
  @Prop({ type: Boolean, default: false })
  disabled!: boolean

  get printerFile (): AppFile | undefined {
    const currentFile = this.$store.state.printer.printer.current_file

    if (currentFile.filename) {
      return currentFile
    }
  }

  get printerFileLoaded () {
    const file = this.$store.getters['gcodePreview/getFile']
    const printerFile = this.printerFile

    if (!file || !printerFile) {
      return false
    }

    return (file.path + '/' + file.filename) === (printerFile.path + '/' + printerFile.filename)
  }

  get noMoves () {
    return this.$store.getters['gcodePreview/getMoves'].length === 0
  }

  async loadCurrent () {
    const file = this.$store.state.printer.printer.current_file as AppFile
    this.getGcode(file)
      .then(response => response?.data)
      .then((gcode: AxiosResponse) => {
        this.$store.dispatch('gcodePreview/loadGcode', {
          file,
          gcode
        })
      })
      .catch(e => e)
      .finally(() => {
        this.$store.dispatch('files/removeFileDownload')
      })
  }

  resetFile () {
    this.$store.dispatch('gcodePreview/reset')
  }
}
