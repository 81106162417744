



























































































import { AppUser } from '@/store/auth/types'
import { Component, Vue } from 'vue-property-decorator'
import UserConfigDialog from './UserConfigDialog.vue'
import ApiKeyDialog from './ApiKeyDialog.vue'

@Component({
  components: {
    UserConfigDialog,
    ApiKeyDialog
  }
})
export default class AuthSettings extends Vue {
  search = ''
  categoryId: string | undefined = undefined

  userDialogState: any = {
    open: false,
    user: null,
    handler: null
  }

  apiKeyDialogState: any = {
    open: false
  }

  get users () {
    return this.$store.getters['auth/getUsers']
  }

  get currentUser () {
    const currentUser = this.$store.getters['auth/getCurrentUser']
    return (currentUser && currentUser.username) ? currentUser.username : ''
  }

  handleAddUserDialog () {
    this.userDialogState = {
      open: true,
      user: { username: '', password: '' },
      handler: this.handleSaveUser
    }
  }

  handleEditUserDialog (user: AppUser) {
    this.userDialogState = {
      open: true,
      user,
      handler: this.handleSaveUser
    }
  }

  handleApiKeyDialog () {
    this.apiKeyDialogState.open = true
  }

  handleRemoveUser (user: AppUser) {
    this.$confirm(
      this.$tc('app.general.simple_form.msg.confirm'),
      { title: this.$tc('app.general.label.confirm'), color: 'card-heading', icon: '$error' }
    )
      .then(res => {
        if (res) {
          this.$store.dispatch('auth/removeUser', user)
        }
      })
  }

  async handleSaveUser (user: AppUser) {
    await this.$store.dispatch('auth/addUser', user)

    // We only want to check trust if this is the first user being added.
    if (this.users.length === 0) this.$store.dispatch('auth/checkTrust')
  }
}
