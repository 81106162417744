








































import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({})
export default class AppSetting extends Vue {
  @Prop({ type: String, default: '' })
  title!: string;

  @Prop({ type: String })
  subTitle!: string;

  @Prop({ type: String })
  help!: string;

  @Prop({ type: String })
  accentColor!: string;

  @Prop({ type: Number, default: 6 })
  rCols!: number;

  get cols () {
    return [12 - this.rCols, this.rCols]
  }

  get hasClick () {
    return this.$listeners && this.$listeners.click
  }

  get hasSubTitle () {
    return (
      this.$slots['sub-title'] ||
      this.$scopedSlots['sub-title'] ||
      this.subTitle
    )
  }

  get classes () {
    return {
      setting__link: this.hasClick
    }
  }
}
