




























import { Component, Vue } from 'vue-property-decorator'
import SystemLoadChart from './SystemLoadChart.vue'
import SystemMemoryChart from './SystemMemoryChart.vue'
import KlipperLoadChart from './KlipperLoadChart.vue'
import MoonrakerLoadChart from './MoonrakerLoadChart.vue'
import McuLoadChart from './McuLoadChart.vue'

@Component({
  components: {
    SystemLoadChart,
    SystemMemoryChart,
    KlipperLoadChart,
    MoonrakerLoadChart,
    McuLoadChart
  }
})
export default class PrinterStatsCard extends Vue {
  get procStats () {
    return this.$store.getters['server/getProcessStats']
  }

  get systemStats () {
    return this.$store.getters['printer/getSystemStats']
  }

  get mcus () {
    return this.$store.getters['printer/getMcus']
  }
}
