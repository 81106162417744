













































































































import { Component, Mixins } from 'vue-property-decorator'
import MacroCategoryDialog from './MacroCategoryDialog.vue'
import StateMixin from '@/mixins/state'
import { Macro, MacroCategory } from '@/store/macros/types'

@Component({
  components: {
    MacroCategoryDialog
  }
})
export default class MacroSettings extends Mixins(StateMixin) {
  categoryDialogState: any = {
    open: false,
    title: 'add',
    label: '',
    category: null,
    name: '',
    rules: [],
    handler: this.handleAddCategory
  }

  get categories () {
    return this.$store.getters['macros/getCategories']
  }

  get uncategorizedMacros () {
    const uncategorized = this.$store.getters['macros/getMacrosByCategory']()
    const count = uncategorized.length
    const visible = uncategorized.filter((macro: Macro) => macro.visible).length
    return {
      count,
      visible
    }
  }

  handleAddCategoryDialog () {
    this.categoryDialogState = {
      open: true,
      title: this.$t('app.general.label.add_category'),
      label: this.$t('app.general.label.name'),
      category: null,
      name: '',
      rules: [
        (v: string) => !!v || this.$t('app.general.simple_form.error.required'),
        (v: string) => this.categories.findIndex((c: MacroCategory) => c.name.toLowerCase() === v.toLowerCase()) < 0 || this.$t('app.general.simple_form.error.exists')
      ],
      handler: this.handleAddCategory
    }
  }

  handleEditCategoryDialog (category: MacroCategory) {
    this.categoryDialogState = {
      open: true,
      title: this.$t('app.general.label.edit_category'),
      label: this.$t('app.general.label.name'),
      category,
      name: category.name,
      rules: [
        (v: string) => !!v || this.$t('app.general.simple_form.error.required'),
        (v: string) => this.categories.findIndex((c: MacroCategory) => c.name.toLowerCase() === v.toLowerCase()) < 0 || this.$t('app.general.simple_form.error.exists')
      ],
      handler: this.handleEditCategory
    }
  }

  handleRemoveCategory (category: MacroCategory) {
    this.$store.dispatch('macros/removeCategory', category)
  }

  handleAddCategory (category: string) {
    this.$store.dispatch('macros/addCategory', category)
  }

  handleEditCategory (name: string) {
    const category = {
      ...this.categoryDialogState.category,
      name
    }
    this.$store.dispatch('macros/editCategory', category)
  }

  handleCategoryClick (category: MacroCategory) {
    const id = (category) ? category.id : 0
    this.$router.push(`/settings/macros/${id}`)
  }
}
