






















































import { Component, Mixins } from 'vue-property-decorator'
import StateMixin from '@/mixins/state'
import AppSetting from '@/components/ui/AppSetting.vue'
import { TimelapseSettings } from '@/store/timelapse/types'
import { SocketActions } from '@/api/socketActions'

@Component({
  components: { AppSetting }
})
export default class TimelapseSettingsCard extends Mixins(StateMixin) {
  get enabledBlocked () {
    return this.$store.getters['timelapse/isBlockedSetting']('enabled')
  }

  get enabled () {
    return this.settings?.enabled
  }

  set enabled (value: boolean) {
    SocketActions.machineTimelapseSetSettings({ enabled: value })
  }

  get autoRenderBlocked () {
    return this.$store.getters['timelapse/isBlockedSetting']('autorender')
  }

  get autoRender () {
    return this.settings?.autorender
  }

  set autoRender (value: boolean) {
    SocketActions.machineTimelapseSetSettings({ autorender: value })
  }

  get frameCount () {
    return this.$store.getters['timelapse/getLastFrame']?.count
  }

  get settings (): TimelapseSettings {
    return this.$store.getters['timelapse/getSettings']
  }

  subtitleIfBlocked (blocked: boolean): string {
    return blocked ? this.$tc('app.timelapse.tooltip.managed_by_moonraker') : ''
  }
}
